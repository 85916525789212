var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-layout",
    { attrs: { wrap: "", "justify-start": "", "fill-height": "" } },
    [
      _c(
        "v-flex",
        {
          attrs: {
            xs12: "",
            sm12: "",
            lg6: "",
            "text-center": "",
            "align-self-center": "",
          },
        },
        [
          _c("v-img", {
            attrs: {
              src: require("@/assets/images/admin-login.png"),
              height: "100vh",
              cover: "",
            },
          }),
        ],
        1
      ),
      _c(
        "V-flex",
        {
          attrs: {
            xs12: "",
            sm12: "",
            lg5: "",
            xl4: "",
            "px-4": "",
            "px-sm-0": "",
          },
        },
        [
          _c(
            "v-layout",
            { attrs: { wrap: "", "justify-center": "", "pb-12": "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "", sm8: "", lg8: "" } },
                [
                  _c(
                    "v-layout",
                    {
                      attrs: { wrap: "", "justify-start": "", "pt-xl-12": "" },
                    },
                    [
                      _c("v-flex", { attrs: { xs12: "", "pt-12": "" } }, [
                        _c("span", { staticClass: "title-main" }, [
                          _vm._v("Welcome Admin"),
                        ]),
                        _c("br"),
                        _c("span", { staticClass: "subtitle-2" }, [
                          _vm._v(" Enter details to login "),
                        ]),
                      ]),
                      _c(
                        "v-flex",
                        {
                          attrs: {
                            xs12: "",
                            sm10: "",
                            "pt-12": "",
                            "py-xl-12": "",
                          },
                        },
                        [
                          _c(
                            "v-layout",
                            {
                              attrs: {
                                wrap: "",
                                "justify-center": "",
                                "pt-xl-12": "",
                              },
                            },
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", "pt-4": "" } },
                                [
                                  _c("p", { staticClass: "input-label" }, [
                                    _vm._v("Email"),
                                  ]),
                                  _c("v-text-field", {
                                    attrs: {
                                      placeholder: "Enter Email",
                                      dense: "",
                                      "hide-details": "",
                                      outlined: "",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", "pt-4": "" } },
                                [
                                  _c("p", { staticClass: "input-label" }, [
                                    _vm._v("Password"),
                                  ]),
                                  _c("v-text-field", {
                                    attrs: {
                                      placeholder: "Enter Password",
                                      type: "password",
                                      dense: "",
                                      "hide-details": "",
                                      outlined: "",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                {
                                  attrs: {
                                    xs12: "",
                                    "pt-4": "",
                                    "text-right": "",
                                  },
                                },
                                [
                                  _c(
                                    "router-link",
                                    {
                                      attrs: {
                                        to: { name: "forgot-password" },
                                      },
                                    },
                                    [
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "input-label primary--text",
                                        },
                                        [_vm._v("Forgot Password?")]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                {
                                  attrs: {
                                    xs12: "",
                                    "pt-6": "",
                                    "pt-xl-12": "",
                                  },
                                },
                                [
                                  _c("AppButton", {
                                    attrs: {
                                      block: "",
                                      "custom-class": "py-5",
                                      label: "Login",
                                    },
                                    on: { submit: _vm.login },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-layout",
            { attrs: { wrap: "", "justify-center": "", "pt-xl-12": "" } },
            [
              _c(
                "v-flex",
                {
                  attrs: {
                    xs12: "",
                    sm8: "",
                    "align-self-baseline": "",
                    "pt-12": "",
                  },
                },
                [
                  _c("span", { staticClass: "subtitle-2" }, [
                    _vm._v("Copyright zenrion@2022"),
                  ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }