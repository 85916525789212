/* This File is for {purpose} */

<template>
  <v-layout wrap justify-start fill-height>
    <v-flex xs12 sm12 lg6 text-center align-self-center>
      <v-img src="@/assets/images/admin-login.png" height="100vh" cover></v-img>
    </v-flex>
    <V-flex xs12 sm12 lg5 xl4 px-4 px-sm-0>
      <v-layout wrap justify-center pb-12>
        <v-flex xs12 sm8 lg8>
          <v-layout wrap justify-start pt-xl-12>
            <v-flex xs12 pt-12>
              <span class="title-main">Welcome Admin</span><br />
              <span class="subtitle-2"> Enter details to login </span>
            </v-flex>
            <v-flex xs12 sm10 pt-12 py-xl-12>
              <v-layout wrap justify-center pt-xl-12>
                <v-flex xs12 pt-4>
                  <p class="input-label">Email</p>
                  <v-text-field
                    placeholder="Enter Email"
                    dense
                    hide-details
                    outlined
                  />
                </v-flex>
                <v-flex xs12 pt-4>
                  <p class="input-label">Password</p>
                  <v-text-field
                    placeholder="Enter Password"
                    type="password"
                    dense
                    hide-details
                    outlined
                  />
                </v-flex>
                <v-flex xs12 pt-4 text-right>
                  <router-link :to="{ name: 'forgot-password' }">
                    <p class="input-label primary--text">Forgot Password?</p>
                  </router-link>
                </v-flex>
                <v-flex xs12 pt-6 pt-xl-12>
                  <AppButton
                    block
                    custom-class="py-5"
                    label="Login"
                    @submit="login"
                  />
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
      <v-layout wrap justify-center pt-xl-12>
        <v-flex xs12 sm8 align-self-baseline pt-12>
          <span class="subtitle-2">Copyright zenrion@2022</span>
        </v-flex>
      </v-layout>
    </V-flex>
  </v-layout>
</template>

<script>
export default {
  name: "Login",
  methods: {
    login() {
      localStorage.setItem("type", "admin");
      this.$router.push({ name: "dashboard" });
    },
  },
};
</script>

<style scoped>
.v-text-field--outlined >>> fieldset {
  border-color: #c0cbe0 !important;
}

.v-text-field--outlined::v-deep input::placeholder,
.v-text-field--outlined::v-deep textarea::placeholder {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.02em;
  color: #9fadc8;
}

.v-input ::v-deep input,
.v-input ::v-deep textarea,
.v-input ::v-deep .v-select__selection {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: -0.02em;
  color: #081734;
}

.v-input ::v-deep label {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: -0.02em;
  color: #081734;
}
</style>
